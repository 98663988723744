import {
  BooleanField,
  FunctionField,
  Identifier,
  NumberField,
  RecordContextProvider,
  TextField,
  useDataProvider,
  useRecordContext,
  useShowContext,
} from "react-admin";
import { MutationFunction, MutationKey, useQuery } from "react-query";

import { EMAIL_CONFIRMATION_STATUS_TOOLTIP_TEXT } from "../../constants";
import { useModalState } from "../../hooks";
import {
  AccountAnalyticsResponse,
  AccountResponse,
  AccountWorkatoConnectedAppsResponse,
  Action,
  CustomDataProvider,
  DataKind,
  Region,
  Resource,
  UserResponse,
} from "../../types";
import { resourceUtils } from "../../utils";
import { routes } from "../../utils/Routes";
import { componentUtils } from "../component_utils";
import {
  ActionMenu,
  Authorize,
  ConfirmationModal,
  CustomDatagrid,
  CustomDateTimeField,
  CustomMenuItem,
  CustomReferenceField,
  CustomReferenceManyField,
  CustomShow,
  ExportAccountModal,
  ForceUnblockAccountMenuItem,
  LabelWithInfoTooltip,
  LinkablePlanInfoField,
  MutationActionMenuItem,
  ShowTable,
  TransferProjectsModal,
  UnblockAccountMenuItem,
  UpdateCreditCardModal,
} from "../custom";

interface UpdateSpecsForAllProjectsMutationFnVariables {
  accountId: Identifier;
  enable: boolean;
}

// Custom Actions

function AccountHistory() {
  const record = useRecordContext<AccountResponse>();

  if (record) {
    return (
      // We use the following directive to suppress the type errors arising from
      // usage of the `component` and `href` props. Despite the type errors, the
      // component works as expected with the props.
      <CustomMenuItem
        // @ts-expect-error
        component="a"
        href={routes.computeUsHistoryForAccount(record.id)}
      >
        Account History
      </CustomMenuItem>
    );
  } else {
    return null;
  }
}

function ExportAccount() {
  const record = useRecordContext<AccountResponse>();

  if (record) {
    return <ExportAccountButton account={record} />;
  } else {
    return null;
  }
}

function ExportAccountButton(props: { account: AccountResponse }) {
  const { handleClose, handleOpen, modalOpen } = useModalState();

  return (
    <>
      <CustomMenuItem onClick={handleOpen}>
        Create Account Export
      </CustomMenuItem>
      <ExportAccountModal
        handleClose={handleClose}
        modalOpen={modalOpen}
        account={props.account}
      />
    </>
  );
}

function UpdateSpecsForAllProjects(props: { enable: boolean }) {
  const record = useRecordContext<AccountResponse>();
  const dataProvider = useDataProvider<CustomDataProvider>();

  if (record) {
    const mutationFn: MutationFunction<
      void[],
      UpdateSpecsForAllProjectsMutationFnVariables
    > = (variables: UpdateSpecsForAllProjectsMutationFnVariables) => {
      const promiseFns = Object.values(Region).map((region) => {
        if (variables.enable) {
          return dataProvider.enableSpecsForAllProjects(
            region,
            variables.accountId
          );
        } else {
          return dataProvider.disableSpecsForAllProjects(
            region,
            variables.accountId
          );
        }
      });

      return Promise.all(promiseFns);
    };

    const mutationKey: MutationKey = [
      "UpdateSpecsForAllProjects",
      props.enable,
      record.id,
    ];

    const status = props.enable ? "Enable" : "Disable";
    const label = `${status} Specs for All Projects`;
    const successMessage = `${status}d specifications on all projects for account
    successfully. Please allow a few minutes for this to take effect ✅`;

    return (
      <MutationActionMenuItem
        label={label}
        mutationFn={mutationFn}
        mutationKey={mutationKey}
        mutationVariables={{
          accountId: record.id,
          enable: props.enable,
        }}
        successMessage={successMessage}
        disabled={!record.has_specs}
        skipPageRefresh={true}
      />
    );
  } else {
    return null;
  }
}

function ForceUnblockAccount() {
  const accountRecord = useRecordContext<AccountResponse>();

  if (accountRecord) {
    return <ForceUnblockAccountMenuItem accountRecord={accountRecord} />;
  } else {
    return null;
  }
}

function TransferProjects() {
  const record = useRecordContext<AccountResponse>();

  const { handleClose, handleOpen, modalOpen } = useModalState();

  if (record) {
    return (
      <>
        <CustomMenuItem onClick={handleOpen}>Transfer Projects</CustomMenuItem>
        <TransferProjectsModal
          account={record}
          handleClose={handleClose}
          modalOpen={modalOpen}
        />
      </>
    );
  } else {
    return null;
  }
}

function UnblockAccount() {
  const accountRecord = useRecordContext<AccountResponse>();

  if (accountRecord) {
    return <UnblockAccountMenuItem accountRecord={accountRecord} />;
  } else {
    return null;
  }
}

function UpdateCreditCard() {
  const accountRecord = useRecordContext<AccountResponse>();

  if (accountRecord) {
    return <UpdateCreditCardButton accountRecord={accountRecord} />;
  } else {
    return null;
  }
}

function UpdateCreditCardButton(props: { accountRecord: AccountResponse }) {
  // prettier-ignore
  const { handleClose: handleCloseForConfirm, handleOpen: handleOpenForConfirm, modalOpen: modalOpenForConfirm } = useModalState();
  // prettier-ignore
  const { handleClose: handleCloseForUpdate , handleOpen: handleOpenForUpdate, modalOpen: modalOpenForUpdate } = useModalState();

  const isAccountBasic = componentUtils.isAccountBasic(props.accountRecord);
  const shouldConfirm = !props.accountRecord.customer_id && isAccountBasic;

  return (
    <>
      <CustomMenuItem
        onClick={shouldConfirm ? handleOpenForConfirm : handleOpenForUpdate}
      >
        Update Credit Card
      </CustomMenuItem>
      <ConfirmationModal
        cancelHandler={handleCloseForConfirm}
        confirmHandler={() => {
          // Close the confirmation modal and open the update modal.
          handleCloseForConfirm();
          handleOpenForUpdate();
        }}
        confirmButtonText="I understand, update credit card."
        confirmButtonVariant="fieldwire-primary"
        description={`Hello! It looks like you are trying to add credit card information
                      for a free account. If that's correct and you are setting up the
                      account for an enterprise plan, go on right ahead. If instead you
                      are setting up a premium Stripe subscription, might we suggest using
                      the "Checkout" flow?`}
        modalOpen={modalOpenForConfirm}
        title="Confirmation"
      />
      <UpdateCreditCardModal
        account={props.accountRecord}
        handleClose={handleCloseForUpdate}
        modalOpen={modalOpenForUpdate}
      />
    </>
  );
}

// Custom Fields

function AccountAnalyticsField(props: { label: string; source: string }) {
  const AccountAnalyticsField = (props: {
    record: AccountResponse;
    source: string;
  }) => {
    const dataProvider = useDataProvider<CustomDataProvider>();

    const { data } = useQuery<{ data: AccountAnalyticsResponse | undefined }>(
      ["getAccountAnalytics", { id: props.record.id }],
      () => dataProvider.getAccountAnalytics(props.record.id)
    );

    if (data?.data) {
      return (
        <RecordContextProvider value={data.data}>
          <NumberField source={props.source} />
        </RecordContextProvider>
      );
    } else {
      return null;
    }
  };

  const record = useRecordContext<AccountResponse>();

  if (record) {
    return <AccountAnalyticsField record={record} source={props.source} />;
  } else {
    return null;
  }
}

function AccountAnalyticsAvgLeadScoreField(_props: { label: string }) {
  const AccountAnalyticsField = (props: { record: AccountResponse }) => {
    const dataProvider = useDataProvider<CustomDataProvider>();

    const { data } = useQuery<{ data: AccountAnalyticsResponse | undefined }>(
      ["getAccountAnalytics", { id: props.record.id }],
      () => dataProvider.getAccountAnalytics(props.record.id)
    );

    if (data?.data) {
      return (
        <RecordContextProvider value={data.data}>
          <FunctionField
            render={(record: AccountAnalyticsResponse) => {
              if (record.active_users > 0) {
                const score = record.lead_score / record.active_users;
                return score.toFixed(1);
              } else {
                return "0";
              }
            }}
          />
        </RecordContextProvider>
      );
    } else {
      return null;
    }
  };

  const record = useRecordContext<AccountResponse>();

  if (record) {
    return <AccountAnalyticsField record={record} />;
  } else {
    return null;
  }
}

function AccountUserRole(_props: { label: string; sortable: false }) {
  // Fetch the account record from the show context.
  const { record } = useShowContext<AccountResponse>();

  return (
    <>
      {record && (
        <FunctionField
          render={(userRecord: UserResponse) => {
            if (userRecord.account_id !== record?.id) {
              return "Project User";
            } else {
              // Transform "account_admin" to "Account Admin"
              // or "account_member" to "Account Member".
              return userRecord.account_role
                .split("_")
                .map((s: string) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(" ");
            }
          }}
        />
      )}
    </>
  );
}

function AccountUserIsOwner(_props: { label: string; sortable: false }) {
  // Fetch the account record from the show context.
  const { record: accountRecord } = useShowContext<AccountResponse>();

  // Fetch the user record from the immediate record context.
  const userRecord = useRecordContext<UserResponse>();

  if (accountRecord && userRecord) {
    return (
      // Create mini record context so that the `BooleanField` can access the
      // value of "is_owner" that we compute from the account and user record.
      <RecordContextProvider
        value={{
          is_owner: userRecord.id === accountRecord.owner_user_id,
        }}
      >
        <BooleanField source="is_owner" />
      </RecordContextProvider>
    );
  } else {
    return null;
  }
}

function AccountWorkatoConnectedAppsField(props: { label: string }) {
  const AccountWorkatoConnectedAppsField = (props: {
    record: AccountResponse;
  }) => {
    const dataProvider = useDataProvider<CustomDataProvider>();

    const { data } = useQuery(
      ["getAllAccountWorkatoConnectedApps", { id: props.record.id }],
      async () => {
        const promises = Object.values(Region).map((region) =>
          dataProvider.getAccountWorkatoConnectedApps(region, props.record.id)
        );

        const results = await Promise.all(promises);

        return results.flatMap((result, index) =>
          result.data.map((item: AccountWorkatoConnectedAppsResponse) => ({
            ...item,
            region: Object.keys(Region)[index],
          }))
        );
      }
    );

    if (data) {
      const app_names = data
        .map(
          (item: AccountWorkatoConnectedAppsResponse & { region: string }) =>
            `${item.name} (${item.region})`
        )
        .join(", ");

      return (
        <RecordContextProvider value={{ name: app_names }}>
          <TextField source="name" />
        </RecordContextProvider>
      );
    }

    return null;
  };

  const record = useRecordContext<AccountResponse>();

  if (record) {
    return <AccountWorkatoConnectedAppsField record={record} />;
  } else {
    return null;
  }
}

const fieldwireActions = [
  {
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.Account,
    element: <ExportAccount />,
  },
  {
    checkPermissionForAction: Action.Update,
    checkPermissionForDataKind: DataKind.StripeSubscription,
    element: <UpdateCreditCard />,
  },
  // The following unblock actions can be performed by all admin users.
  // Therefore, we use the combo of `Read` and `User` as the permission
  // check. This is because the default role can read users and all admin
  // users have, at least, the default role.
  {
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.User,
    element: <UnblockAccount />,
  },
  {
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.User,
    element: <ForceUnblockAccount />,
  },
  {
    checkPermissionForAction: Action.Read,
    checkPermissionForDataKind: DataKind.AccountUserCount,
    element: <AccountHistory />,
  },
  {
    // Proxy for `:manage, Project` on the BE.
    checkPermissionForAction: Action.Update,
    checkPermissionForDataKind: DataKind.Project,
    element: <TransferProjects />,
  },
  {
    checkPermissionForAction: Action.Update,
    checkPermissionForDataKind: DataKind.Account,
    element: <UpdateSpecsForAllProjects enable={true} />,
  },
  {
    checkPermissionForAction: Action.Update,
    checkPermissionForDataKind: DataKind.Account,
    element: <UpdateSpecsForAllProjects enable={false} />,
  },
];

// The reason we have to split the account details section into a standalone
// component is so we can fetch the account record from the show context and
// properly hide or show the following fields depending on if the account is
// premium or not: max projects, max sheets, and max users.
//
// A more detailed explanation of why this is necessary can be found here:
// https://marmelab.com/react-admin/Fields.html#hiding-a-field-based-on-the-value-of-another
function AccountDetails() {
  const { record: accountRecord } = useShowContext<AccountResponse>();

  if (accountRecord) {
    const isAccountBasic = componentUtils.isAccountBasic(accountRecord);

    return (
      <ShowTable title="Account Details">
        <TextField label="Id" source="id" />
        <CustomReferenceField
          label="Owner"
          source="owner_user_id"
          reference={Resource.User}
        >
          <FunctionField
            render={(record: UserResponse) =>
              `${record.first_name} ${record.last_name}`
            }
          />
        </CustomReferenceField>
        <LinkablePlanInfoField label="Plan Name" />
        <CustomReferenceField
          label="Company"
          source="company_id"
          reference={Resource.Company}
        >
          <TextField source="name" />
        </CustomReferenceField>
        <TextField label="Owner Email" source="owner_email" />
        <TextField label="Invoice Email" source="invoice_email" />
        <TextField label="Primary Region" source="primary_region" />
        <CustomDateTimeField label="Created At" source="created_at" />
        <CustomDateTimeField label="Updated At" source="updated_at" />
        {/* We have to pass the label here so the Datagrid can pick it up. */}
        <AccountAnalyticsField label="Sum. Lead Score" source="lead_score" />
        {/* We have to pass the label here so the Datagrid can pick it up. */}
        <AccountAnalyticsAvgLeadScoreField label="Avg. Lead Score" />
        {/* We have to pass the label here so the Datagrid can pick it up. */}
        <AccountAnalyticsField label="Total Users" source="active_users" />
        {/* We have to pass the label here so the Datagrid can pick it up. */}
        <AccountAnalyticsField label="30 Day Users" source="active_30_users" />
        <CustomDateTimeField label="Blocked At" source="blocked_at" />
        <NumberField label="Credits" source="credits" />
        <BooleanField label="Is Churn" source="is_churn" />
        <BooleanField label="Is Blockable" source="is_blockable" />
        {isAccountBasic && (
          <NumberField label="Max Projects" source="max_projects" />
        )}
        {isAccountBasic && (
          <NumberField label="Max Sheets" source="max_sheets" />
        )}
        {isAccountBasic && <NumberField label="Max Users" source="max_users" />}
        <BooleanField label="Has Tasks Toggle" source="has_tasks_toggle" />
        <BooleanField
          label="Has Photo Attachments Toggle"
          source="has_photo_attachments_toggle"
        />
        <BooleanField
          label="Is API Access Enabled"
          source="is_api_access_enabled"
        />
        <BooleanField
          label="Is EU Region Enabled"
          source="is_eu_region_enabled"
        />
        <BooleanField label="Are Forms Enabled" source="is_forms_enabled" />
        <BooleanField label="Is 3D BIM Enabled" source="is_3d_bim_enabled" />
        <BooleanField label="Are RFIs Enabled" source="is_pm_enabled" />
        <BooleanField
          label="Are Submittals Enabled"
          source="is_submittals_enabled"
        />
        <BooleanField
          label="Are Change Orders Enabled"
          source="is_change_orders_enabled"
        />
        <BooleanField label="Is Budget Enabled" source="is_budget_enabled" />
        <BooleanField label="Are Specifications Enabled" source="has_specs" />
        <BooleanField
          label="Is Plan Text Search Enabled"
          source="is_plan_text_search_enabled"
        />
        <BooleanField
          label="Access Control Export URL Enabled"
          source="access_control_export_url_enabled"
        />
        <BooleanField
          label="Is Auto Charge Overage"
          source="is_auto_charge_overage"
        />
        <TextField label="Customer" source="customer_id" />
        <TextField label="Hilti Customer Id" source="hilti_customer_id" />
        <TextField label="Hilti Contact Id" source="hilti_contact_id" />
        <TextField label="Fieldwire Rep" source="fw_rep_admin_user_id" />
        <TextField label="Invoice Company Name" source="company_name" />
        <TextField label="Invoice Country" source="invoice_country" />
        <TextField label="Invoice Company Address" source="company_address" />
        <TextField label="Invoice City" source="invoice_city" />
        <TextField label="Invoice State" source="invoice_state" />
        <TextField label="Invoice Postal Code" source="invoice_postal_code" />
        <BooleanField label="Is User Limit" source="is_user_limit" />
        <TextField label="Market" source="market" />
        {/* MISSING: Scores Cached On */}
        <FunctionField
          label="SSO Domains"
          render={(record: AccountResponse) => record.sso_domains?.join(", ")}
        />
        <BooleanField
          label="Daily Report Active"
          source="daily_report_active"
        />
        <BooleanField label="Has Workato" source="has_workato" />
        <AccountWorkatoConnectedAppsField label="Integrations - Connected apps" />
      </ShowTable>
    );
  } else {
    return null;
  }
}

function AccountProjects(props: { region: Region }) {
  const title = `Account Projects - ${props.region.toUpperCase()}`;
  const projectResource = resourceUtils.projectResourceFor(props.region);

  return (
    <Authorize
      action={Action.Read}
      dataKind={DataKind.Project}
      disableUnauthorizedMessage
    >
      <ShowTable rowFlexDirection="column" title={title}>
        <CustomReferenceManyField
          label={false}
          defaultSort={{ field: "created_at", order: "DESC" }}
          displayPagination={true}
          reference={projectResource}
          target={Resource.Account}
        >
          <CustomDatagrid>
            <TextField label="Name" source="name" />
            <CustomDateTimeField label="Created At" source="created_at" />
            <NumberField
              label="Sum. Lead Score"
              source="lead_score"
              sortable={false}
            />
            <NumberField
              label="Avg. Lead Score"
              source="lead_score_avg"
              sortable={false}
            />
            <NumberField
              label="Total Users"
              source="total_users"
              sortable={false}
            />
            <NumberField
              label="30 Day Users"
              source="active_30_users"
              sortable={false}
            />
            <NumberField label="Tasks Count" source="tasks_sequence_counter" />
            <BooleanField
              label={
                <LabelWithInfoTooltip
                  labelText="Is Sample Project"
                  tooltipText="Sample projects are not included in analytics."
                />
              }
              source="is_sample_project"
              sortable={false}
            />
            <BooleanField label="Are Forms Enabled" source="is_forms_enabled" />
            <BooleanField
              label="Is Plan Text Search Enabled"
              source="is_plan_text_search_enabled"
              sortable={false}
            />
            <CustomDateTimeField
              label="Archived At"
              source="archived_at"
              sortable={false}
            />
            <CustomDateTimeField label="Deleted At" source="deleted_at" />
          </CustomDatagrid>
        </CustomReferenceManyField>
      </ShowTable>
    </Authorize>
  );
}

function AccountShow() {
  return (
    <CustomShow
      checkPermissionFor={DataKind.Account}
      displayDelete={false}
      displayEdit={true}
      fieldwireActions={<ActionMenu fieldwireActions={fieldwireActions} />}
    >
      {/* START OF ACCOUNT DETAILS */}
      {/*
        To understand why we need to have AccountDetails as a standalone
        component, please read the comment above its definition.
      */}
      <AccountDetails />
      {/* END OF ACCOUNT DETAILS */}

      {/* START OF ACCOUNT USERS */}
      <Authorize
        action={Action.Read}
        dataKind={DataKind.User}
        disableUnauthorizedMessage
      >
        <ShowTable rowFlexDirection="column" title="Account Users">
          <CustomReferenceManyField
            label={false}
            displayPagination={true}
            reference={Resource.User}
            target={Resource.Account}
          >
            <CustomDatagrid>
              <TextField label="Email" source="email" />
              <TextField label="First Name" source="first_name" />
              <TextField label="Last Name" source="last_name" />
              <TextField label="Company" source="company" sortable={false} />
              <CustomDateTimeField label="Created At" source="created_at" />
              <TextField
                label={
                  <LabelWithInfoTooltip
                    labelText="Email Confirmation Status"
                    tooltipText={EMAIL_CONFIRMATION_STATUS_TOOLTIP_TEXT}
                  />
                }
                source="email_confirmation_status"
                sortable={false}
              />
              <BooleanField
                label="Is Approved"
                source="is_approved"
                sortable={false}
              />
              <CustomDateTimeField
                label="Current Sign In At"
                source="current_sign_in_at"
              />
              <CustomDateTimeField label="Locked At" source="locked_at" />
              {/* We have to pass the label here so the Datagrid can pick it up. */}
              <CustomReferenceField
                label="Invited By"
                source="invited_by_id"
                reference={Resource.User}
                sortable={false}
              >
                <FunctionField
                  render={(record: UserResponse) =>
                    `${record.first_name} ${record.last_name}`
                  }
                />
              </CustomReferenceField>
              <AccountUserRole label="Account Role" sortable={false} />
              <AccountUserIsOwner label="Is Owner" sortable={false} />
            </CustomDatagrid>
          </CustomReferenceManyField>
        </ShowTable>
      </Authorize>
      {/* END OF ACCOUNT USERS */}

      {/* START OF ACCOUNT PROJECTS */}
      {/*
        Showing US before EU since vast majority of our accounts are
        US based & we would like to reduce scrolling as much as possible.
      */}
      <AccountProjects region={Region.Us} />
      <AccountProjects region={Region.Eu} />
      {/* END OF ACCOUNT PROJECTS */}

      {/* START OF CHARGES */}
      <Authorize
        action={Action.Read}
        dataKind={DataKind.Charge}
        disableUnauthorizedMessage
      >
        <ShowTable rowFlexDirection="column" title="Charges">
          <CustomReferenceManyField
            defaultSort={{ field: "created_at", order: "DESC" }}
            displayPagination={true}
            label={false}
            reference={Resource.Charge}
            target={Resource.Account}
          >
            <CustomDatagrid>
              <TextField label="Id" source="id" />
              <TextField
                label="Stripe Plan"
                source="stripe_plan_id"
                sortable={false}
              />
              <TextField label="Stripe Charge" source="stripe_charge_id" />
              <TextField
                label="Invoice S3 File"
                source="invoice_s3_file_id"
                sortable={false}
              />
              <TextField label="Category" source="category" />
              <TextField
                label="Last Four"
                source="last_four"
                sortable={false}
              />
              <TextField
                label="Card Type"
                source="card_type"
                sortable={false}
              />
              <NumberField label="Covered Charged" source="covered_charged" />
              <NumberField label="Overage Charged" source="overage_charged" />
              <NumberField label="Deductions" source="deductions" />
              <NumberField label="Starting Balance" source="starting_balance" />
              <NumberField label="Total Charged" source="total_charged" />
              <NumberField label="Quantity" source="quantity" />
              <CustomDateTimeField label="Created At" source="created_at" />
              <CustomDateTimeField
                label="Charged Date"
                source="charged_date"
                sortable={false}
              />
              <TextField label="Premium Plan Type" source="premium_plan_type" />
              <TextField
                label="Premium Plan"
                source="premium_plan_id"
                sortable={false}
              />
              <TextField label="State" source="state" />
            </CustomDatagrid>
          </CustomReferenceManyField>
        </ShowTable>
      </Authorize>
      {/* END OF CHARGES */}
    </CustomShow>
  );
}

export default AccountShow;
